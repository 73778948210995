.flatpickr-calendar {
    border-radius: 8px;
    font-size: 12px;
}

.flatpickr-day.selected.startRange, 
.flatpickr-day.selected.endRange, 
.flatpickr-day.endRange, 
.flatpickr-day.startRange,
.flatpickr-day.selected.startRange:hover, 
.flatpickr-day.selected.endRange:hover, 
.flatpickr-day.endRange:hover, 
.flatpickr-day.startRange:hover {
    background: #E45A5C !important;
    border: unset !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}


